import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, RouterModule, RouterOutlet } from '@angular/router';

import { RouteFade } from './shared/animations/routes.animation';
import { ThemeService } from './shared/services/theme.service';
import { NoMobileContentComponent } from './core/no-mobile-content/no-mobile-content.component';
import { BrandsComponent } from './core/brands/brands.component';
import { BrandSelectorComponent } from './brand-selector/brand-selector.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared/shared.module';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, BrandSelectorComponent, BrandsComponent, NoMobileContentComponent, RouterModule, SharedModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [RouteFade]
})
export class AppComponent implements OnInit {

  private themeService = inject(ThemeService);

  ngOnInit(): void {
    this.themeService.setTheme('default');
    this.themeService.updateLayoutWithTheme();
  }

  getRouteAnimation(outlet: RouterOutlet): ActivatedRoute | '' {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

}
