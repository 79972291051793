import { Theme } from '../../models/theme.model';

export const scannetTheme: Theme = {
  name: 'scannet',
  properties: {
    '--background': '#F5F6FA',
    '--brandcolumn-background': 'linear-gradient(180deg, rgba(32,107,60,1) 0%, rgba(45,130,77,1) 100%)',
    '--primary': '#4cd981',
    '--primary-transparent': 'rgba(58, 188, 107, 0.72)',
    '--menu-background': 'linear-gradient(180deg, rgba(54,178,100,1) 0%, rgba(76,217,129,1) 100%)',
    '--content-background': '#ffffff',
    '--border-color': '#DEDEDE'
  }
};
