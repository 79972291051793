import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { ThemeOptions, THEMES } from './models/theme.model';
import { ThemeService } from './services/theme.service';

@NgModule({
  imports: [CommonModule],
  providers: [ThemeService]
})
export class ThemeModule {
  static forRoot(options: ThemeOptions): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        { provide: THEMES, useValue: options.themes }
      ]
    };
  }
}
