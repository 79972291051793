import { Theme } from '../../models/theme.model';

export const dandomainTheme: Theme = {
  name: 'dandomain',
  properties: {
    '--background': '#F5F6FA',
    '--brandcolumn-background': 'linear-gradient(180deg, rgb(176 79 35) 0%, rgb(158 64 21) 100%)',
    '--primary': '#E6723D',
    '--primary-transparent': 'rgba(234, 118, 0, 0.72)',
    '--menu-background': 'linear-gradient(180deg, rgba(230, 114, 61,1) 0%, rgba(173, 75, 30,1) 100%)',
    '--content-background': '#ffffff',
    '--border-color': '#DEDEDE'
  }
};
