import { Theme } from '../../models/theme.model';

export const teamblueTheme: Theme = {
  name: 'teamblue',
  properties: {
    '--background': '#F5F6FA',
    '--brandcolumn-background': 'linear-gradient(180deg, rgba(10,23,41,1) 0%, rgba(16,37,66,1) 100%)',
    '--primary': '#153054',
    '--primary-transparent': 'rgba(21, 48, 84, 0.72)',
    '--menu-background': 'linear-gradient(180deg, rgba(17,39,68,1) 0%, rgba(28,63,110,1) 100%)',
    '--content-background': '#ffffff',
    '--border-color': '#DEDEDE'
  }
};
