import { Component, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { LoginDialogComponent } from './../../shared/components/login-dialog/login-dialog.component';
import { Brand } from './../../shared/models/brand.model';
import { AuthService } from './../../shared/services/auth.service';
import { BrandService } from './../../shared/services/brand.service';
import { RouterModule } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, MatIcon, FlexLayoutModule]
})
export class BrandsComponent {
  brands: Brand[];
  authorized: boolean;

  private brandService = inject(BrandService);
  private authService = inject(AuthService);
  private dialog = inject(MatDialog);

  ngOnInit(): void {
    this.brands = this.brandService.getBrands();
    this.authService.authorizedSubject.subscribe((data) => {
      this.authorized = data;
    });
  }

  onLogin(): void {
    this.dialog.open(LoginDialogComponent, {
      width: '410px'
    });
  }

  onLogout(): void {
    this.authService.logout();
  }

}
