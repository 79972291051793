import { Theme } from '../../models/theme.model';

export const curanetTheme: Theme = {
  name: 'curanet',
  properties: {
    '--background': '#F5F6FA',
    '--brandcolumn-background': 'linear-gradient(180deg, rgba(1, 105, 109,1) 0%, rgba(1, 75, 78,1) 100%)',
    '--primary': '#01969C',
    '--primary-transparent': 'rgba(1, 150, 156, 0.72)',
    '--menu-background': 'linear-gradient(180deg, rgba(1, 150, 156,1) 0%, rgba(1, 135, 140, 1) 100%)',
    '--content-background': '#ffffff',
    '--border-color': '#DEDEDE'
  }
};
