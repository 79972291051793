<div class="brands" fxLayout="column" fxLayoutAlign="space-between">
  <ul fxFlex>
    <li class="brand-selector">
      <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" title="Choose brand"><img src="/assets/images/icons/brand-selector.svg"></a>
    </li>
    <li *ngFor="let brand of brands">
      <a [routerLink]="brand.alias" routerLinkActive="active" [title]="brand.name"><img [src]="'/assets/images/brands/' + brand.alias + '/brand-icon.svg'"></a>
    </li>
  </ul>
  <ul fxFlex="nogrow" *ngIf="!authorized">
    <li class="login">
      <a (click)="onLogin()"><mat-icon>account_circle</mat-icon></a>
    </li>
  </ul>
  <ul fxFlex="nogrow" *ngIf="authorized">
    <li class="login">
      <a (click)="onLogout()" title="Logout"><mat-icon>logout</mat-icon></a>
    </li>
  </ul>
</div>
