import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ApplicationConfig, LOCALE_ID, importProvidersFrom, provideZoneChangeDetection } from "@angular/core";
import { provideClientHydration } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter, withEnabledBlockingInitialNavigation, withInMemoryScrolling } from "@angular/router";
import { AppRoutes } from "./app-routing.module";
import { ThemeModule } from "./shared/theme.module";
import { defaultTheme } from "./shared/brands/themes/default.theme";
import { simplyTheme } from "./shared/brands/themes/simply.theme";
import { curanetTheme } from "./shared/brands/themes/curanet.theme";
import { dandomainTheme } from "./shared/brands/themes/dandomain.theme";
import { scannetTheme } from "./shared/brands/themes/scannet.theme";
import { teamblueTheme } from "./shared/brands/themes/teamblue.theme";
import { register } from "swiper/element";
import { registerLocaleData } from "@angular/common";
import localeEn from '@angular/common/locales/en';

registerLocaleData(localeEn, 'en');
register();

export const applicationConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      ThemeModule.forRoot({
        themes: [defaultTheme, simplyTheme, curanetTheme, dandomainTheme, scannetTheme, teamblueTheme]
      })
    ),
    provideHttpClient(withInterceptorsFromDi()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(AppRoutes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled'
      }),
      withEnabledBlockingInitialNavigation()
    ),
    provideAnimations(),
    { provide: LOCALE_ID, useValue: 'en' },
    provideClientHydration(),
  ]
}