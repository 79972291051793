import { Theme } from '../../models/theme.model';

export const defaultTheme: Theme = {
  name: 'default',
  properties: {
    '--background': '#F5F6FA',
    '--brandcolumn-background': 'linear-gradient(180deg, rgba(36,24,35,1) 0%, rgba(57,57,57,1) 100%)',
    '--primary': '#312d31',
    '--primary-transparent': 'rgba(0, 0, 0, 0.7)',
    '--content-background': '#ffffff',
    '--border-color': '#DEDEDE'
  }
};
