import { Component, OnInit, inject } from '@angular/core';

import { Brand } from './../../shared/models/brand.model';
import { BrandService } from './../../shared/services/brand.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-no-mobile-content',
  templateUrl: './no-mobile-content.component.html',
  styleUrls: ['./no-mobile-content.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class NoMobileContentComponent implements OnInit {
  brands: Brand[];

  private brandService = inject(BrandService);

  ngOnInit(): void {
    this.brands = this.brandService.getBrands();
  }

}
