<div class="layout-wrapper">
  <div class="wrapper-main" fxLayout fxHide fxShow.gt-md>
    <div fxFlex="nogrow">
      <app-brands></app-brands>
    </div>
    <div fxFlex>
      <main [@routeFade]="getRouteAnimation(route)">
        <router-outlet #route="outlet"></router-outlet>
      </main>
    </div>
  </div>
  <div fxHide.gt-md class="no-mobile-content" fxLayout fxLayoutAlign="center center">
    <div fxFlex="nogrow" fxFlexAlign="center">
      <app-no-mobile-content></app-no-mobile-content>
    </div>
  </div>
</div>
