import { SeoService } from './../shared/services/seo.service';
import { Component, OnInit, inject } from '@angular/core';

import { Fade } from './../shared/animations/animations';
import { Brand } from './../shared/models/brand.model';
import { BrandService } from './../shared/services/brand.service';
import { ThemeService } from './../shared/services/theme.service';
import { PageHeaderComponent } from '../shared/components/page-header/page-header.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

@Component({
  selector: 'app-brand-selector',
  templateUrl: './brand-selector.component.html',
  styleUrls: ['./brand-selector.component.scss'],
  animations: [Fade],
  standalone: true,
  imports: [CommonModule, RouterModule, PageHeaderComponent, SharedModule]
})
export class BrandSelectorComponent implements OnInit {
  brands: Brand[];

  private brandService = inject(BrandService);
  private themeService = inject(ThemeService);
  private seoService = inject(SeoService);
0
  ngOnInit(): void {
    this.seoService.setTitle('Brand guidelines');
    this.brands = this.brandService.getBrands();
    this.themeService.setTheme('default');
    this.themeService.updateLayoutWithTheme();
  }

}
