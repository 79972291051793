import { Theme } from '../../models/theme.model';

export const simplyTheme: Theme = {
  name: 'simply',
  properties: {
    '--background': '#F5F6FA',
    '--brandcolumn-background': 'linear-gradient(180deg, rgba(10, 29, 46) 0%, rgba(10, 29, 46) 100%)',
    '--primary': '#B522C1',
    '--primary-transparent': 'rgba(69, 14, 53, 0.8)',
    '--menu-background': 'linear-gradient(180deg, rgba(18, 43, 63) 0%, rgba(18, 43, 63) 100%)',
    '--content-background': '#ffffff',
    '--border-color': '#DEDEDE'
  }
};
