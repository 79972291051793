import { Routes } from '@angular/router';

import { BrandSelectorComponent } from './brand-selector/brand-selector.component';
import { Error404Component } from './core/error404/error404.component';

export const AppRoutes: Routes = [
  { path: '', component: BrandSelectorComponent, pathMatch: 'full' },
  { path: '404', component: Error404Component },
  { path: ':brand', loadChildren: () => import('./guide/guide.module').then(m => m.GuideModule) },
];
