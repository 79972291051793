<div class="content">
  <app-page-header title="Brand guidelines" [login]="true"></app-page-header>
  <section class="brands" fxLayout>
    <div class="brand" *ngFor="let brand of brands" fxFlex="33.333%">
      <a [routerLink]="brand.alias">
        <div fxLayout fxLayoutAlign="center center" class="inner">
          <img [src]="'/assets/images/brands/' + brand.alias + '/logo.svg'" [title]="brand.name">
        </div>
      </a>
    </div>
  </section>
</div>
